<template>
  <app-form-view
    multipart
    app="contact"
    model="personaldocument"
    api-url="contact/personal-doc/"
    :title="$t('menu.CompanyCertificate')"
    :state="formData.state"
    :approveItems="approveFields"
    :stateItems="stateItems"
    :prepare-data="prepareData"
    v-model="formData"
    @input="loadDocuments"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" md="6">
          <v-row
            ><v-col cols="12" lg="12">
              <app-input
                name="name"
                :label="$t('fields.documentName')"
                :view="view"
                v-model="formData.document_name"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="contact"
                type="chips-server"
                rules="required"
                :label="$t('fields.contact')"
                :view="view"
                :binds="{
                  apiUrl: 'contact/contact/?contact_type=company',
                  chip: {
                    color: 'secondary',
                    icon: 'mdi-account-circle-outline',
                    value: 'name',
                    router: contactRouter
                  },
                  cacheItems: false
                }"
                v-model="formData.contact_ids"
              />

              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_contact_ids"
              />
            </v-col>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="type"
                rules="required"
                :label="$t('fields.type')"
                :view="view"
                value="company"
                disabled
              >
              </app-input>

              <app-input
                v-if="isSentToApprove"
                name="approveType"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_type"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="documentTemplate"
                rules="required"
                :label="$t('fields.documentTemplate')"
                :view="view"
                value="หนังสือรับรองบริษัท"
                disabled
              />

              <app-input
                v-if="isSentToApprove"
                name="approveDocumentTemplate"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_template_id"
              />
            </v-col>

            <template v-if="formData.document_template_id">
              <v-col
                v-for="field in documentTemplateFields"
                cols="12"
                md="6"
                class="app-input--approve-wrapper"
                :key="field.field"
              >
                <app-input
                  :rules="{
                    required: true,
                    numeric: field.field_type === 'number'
                  }"
                  :name="field.field"
                  :type="field.field_type"
                  :label="field.name"
                  :items="fieldSelectionItems(field)"
                  :view="view"
                  v-model="formData.fields[field.field]"
                />

                <app-input
                  v-if="isSentToApprove"
                  type="switch"
                  class="approve"
                  :name="`approve-${field.name}`"
                  :label="$t('fields.approve')"
                  v-model="formData[`approve_${field.field}`]"
                />
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="customer_name"
                type="select-server"
                rules="required"
                :label="$t('fields.customerCompany')"
                :view="view"
                :binds="{
                  apiUrl: 'customer-company/customer_company/?active=true'
                }"
                v-model="formData.customer_company_id"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveCustomerCompany"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_customer_company"
              />
            </v-col>
          </v-row>
          <v-row v-if="!view">
            <v-col cols="12" lg="12">
              <app-input
                name="documents"
                type="file"
                :label="$t('fields.document')"
                :view="view"
                :binds="{ multiple: true, chips: true }"
                v-model="formData.documents"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="12">
              <app-input
                name="note"
                type="textarea"
                :label="$t('fields.note')"
                :view="view"
                v-model="formData.note"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <app-preview-file
            :file-ready="documentReady || mode === 'create'"
            :files="formData.documents || []"
          />
        </v-col>
      </v-row>
      <v-card>
        <v-tabs background-color="primary" dark>
          <v-tab>{{ $t('label.committee') }}</v-tab>
          <v-tab>{{ $t('label.termsofsign') }}</v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="special_doc"
                    model="directordocument"
                    :headers="fieldHeaders"
                    :search="search"
                    :serverItems="items"
                    :clientItems="items"
                    :loading="loading"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    v-bind="binds"
                    v-model="selected"
                    itemKey="code"
                    :server-items-length="serverItemsLength"
                    @delete="onDeleteItem"
                    @edit="onEdit"
                    @server="getItems"
                    @client="getClientItems"
                    v-if="mode !== 'create'"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>{{
                              $t('label.committee')
                            }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog v-model="dialog" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="selected && mode === 'edit'"
                                    color="error"
                                    @click="onDelete(selected)"
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.contact_id"
                                          name="contact_id"
                                          type="select-server"
                                          :label="$t('fields.contact_id')"
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'contact/contact/?contact_type=individual'
                                          }"
                                          @input="getDataDetails"
                                        ></app-input>
                                        <app-input
                                          v-model="editedItem.prefix_id"
                                          name="prefix_id"
                                          type="select-server"
                                          :label="$t('fields.prefix_id')"
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'contact/contact-prefix/?type=individual'
                                          }"
                                          ref="prefix"
                                        ></app-input>
                                        <app-input
                                          name="name"
                                          :label="$t('fields.name')"
                                          :view="view"
                                          v-model="editedItem.first_name"
                                        >
                                        </app-input>
                                        <app-input
                                          name="name"
                                          :label="$t('fields.lastName')"
                                          :view="view"
                                          v-model="editedItem.last_name"
                                        >
                                        </app-input>
                                        <app-input
                                          name="id"
                                          :label="$t('fields.id')"
                                          :view="view"
                                          v-model="editedItem.id_card"
                                        >
                                        </app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    app="special_doc"
                    model="signingcondition"
                    :headers="headersConditionItem"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :search="search"
                    :serverItems="conditionItems"
                    :clientItems="conditionItems"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selectedConditionItems"
                    :server-items-length="serverConditionItemsLength"
                    @server="getConditionItems"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>
                              Terms of Signing
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-dialog
                              v-model="dialogCheckCondition"
                              width="1000"
                            >
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                  color="green lighten-1"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="dialogCheckCondition = true"
                                >
                                  ตรวจสอบผู้มีอำนาจลงนาม
                                </v-btn>
                              </template>

                              <v-card>
                                <v-card-title class="headline"
                                  >ตรวจสอบผู้มีอำนาจลงนาม</v-card-title
                                >
                                <v-card-text>
                                  <template>
                                    <v-container>
                                      <v-row
                                        justify="space-between"
                                        class="text-center"
                                      >
                                        <v-col>
                                          <app-input
                                            name="date"
                                            type="datepicker"
                                            :label="$t('fields.documentDate')"
                                            rules="required"
                                            v-model="dateCheckCondition"
                                          />
                                        </v-col>

                                        <v-col>
                                          <v-btn
                                            color="green"
                                            dark
                                            @click="checkCondition"
                                          >
                                            ตรวจสอบ
                                          </v-btn></v-col
                                        >
                                      </v-row>

                                      <v-row
                                        justify="space-between"
                                        class="text-center"
                                      >
                                        <v-col>
                                          <app-input
                                            name="document"
                                            type="select"
                                            :label="$t('label.documentType')"
                                            :items="documentType"
                                            rules="required"
                                            v-model="documentCheckCondition"
                                          />
                                        </v-col>

                                        <v-col>{{ checkResult }} </v-col>
                                      </v-row>
                                      <v-row
                                        justify="space-between"
                                        class="text-center"
                                      >
                                        <v-col>
                                          <app-input
                                            name="name"
                                            type="select"
                                            label="รายชื่อผู้ลงนามในเอกสาร"
                                            :items="
                                              items.map(
                                                (item) =>
                                                  item['contact_id']['name']
                                              )
                                            "
                                            rules="required"
                                            v-model="listNameCheckCondition"
                                            multiple
                                            chips
                                          />
                                        </v-col>

                                        <v-col> </v-col>
                                      </v-row>
                                    </v-container>
                                  </template>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="dialogCheckCondition = false"
                                  >
                                    ออกจากหน้านี้
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              dark
                              v-show="mode === 'edit'"
                              @click="
                                onDeleteConditionItem(selectedConditionItems)
                              "
                            >
                              Delete</v-btn
                            >
                            <v-dialog
                              v-model="dialogCreateCondition"
                              width="1400"
                            >
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="dialogCreateCondition = true"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                              </template>

                              <v-card>
                                <v-card-title class="headline"
                                  >เงื่อนไขผู้มีอำนาจลงนาม</v-card-title
                                >
                                <v-card-text>
                                  <template>
                                    <v-container>
                                      <v-row
                                        justify="space-between"
                                        class="text-center"
                                      >
                                        <v-col>
                                          <app-input
                                            name="ประเภทเอกสาร"
                                            type="select"
                                            label="ประเภทเอกสาร"
                                            :items="documentRemain"
                                            rules="required"
                                            v-model="document_type"
                                          />
                                        </v-col>

                                        <v-col> </v-col>
                                        <v-col> </v-col>
                                      </v-row>

                                      <v-row
                                        justify="space-between"
                                        class="text-center"
                                      >
                                        <v-col>
                                          <app-input
                                            name="รายชื่อผู้มีอำนาจลงนาม"
                                            type="select"
                                            chips
                                            label="รายชื่อผู้มีอำนาจลงนาม"
                                            :items="
                                              items.map(
                                                (item) =>
                                                  item['contact_id']['name']
                                              )
                                            "
                                            rules="required"
                                            v-model="contact_ids"
                                            multiple
                                          />
                                        </v-col>

                                        <v-col> </v-col>
                                        <v-col> </v-col>
                                      </v-row>
                                      <v-row
                                        justify="space-between"
                                        class="text-center"
                                      >
                                        <v-col>
                                          <app-input
                                            name="เงื่อนไข"
                                            type="select"
                                            label="เงือนไข"
                                            :items="conditionsType"
                                            rules="required"
                                            v-model="conditionValue.type"
                                          />
                                        </v-col>
                                        <v-col>
                                          <app-input
                                            name="จำนวน"
                                            type="text"
                                            label="จำนวน"
                                            :items="contact_ids"
                                            rules="required"
                                            v-model="conditionValue.condition"
                                            v-show="
                                              conditionValue.type ===
                                                'x of all' &&
                                              conditionValue.type !== null &&
                                              conditionValue.type !== undefined
                                            "
                                          />
                                          <app-input
                                            name="ระบุชื่อ"
                                            type="select"
                                            chips
                                            label="ระบุชื่อ"
                                            :items="contact_ids"
                                            v-model="conditionValue.condition"
                                            rules="required"
                                            multiple
                                            v-show="
                                              conditionValue.type !==
                                                'x of all' &&
                                              conditionValue.type !== null &&
                                              conditionValue.type !== undefined
                                            "
                                          />
                                        </v-col>
                                        <v-col>
                                          <v-btn
                                            color="error"
                                            dark
                                            @click="
                                              onDeleteConditionValue(
                                                selectedConditionValue
                                              )
                                            "
                                            >Delete</v-btn
                                          >
                                          <v-btn
                                            color="primary"
                                            dark
                                            @click="saveConditionValue"
                                            >New Item</v-btn
                                          >
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                    <app-table
                                      app="special_doc"
                                      model="signingcondition"
                                      :headers="headersConditionValue"
                                      :search="search"
                                      :serverItems="
                                        savedConditionItem.conditions
                                      "
                                      :clientItems="
                                        savedConditionItem.conditions
                                      "
                                      :loading="loading"
                                      v-bind="binds"
                                      v-model="selectedConditionValue"
                                    ></app-table>
                                  </template>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="dialogCreateCondition = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="saveConditionItem"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <slot :view="view" />
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppPreviewFile from '@components/AppPreviewFile'
import AppTable from '@components/AppTable'
import { getFile } from '@utils/function'

export default {
  name: 'company-certificate-form',
  components: {
    AppInput,
    AppFormView,
    AppPreviewFile,
    AppTable
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: true
    },
    hideDelete: {
      type: Boolean,
      default: false
    },
    binds: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },

    documentTemplateFields() {
      const fields = [...this.formData.document_template_id.fields]
      fields.sort((a, b) => {
        if (a.code < b.code) return -1
        if (a.code > b.code) return 1
        return 0
      })
      return fields
    },
    fieldHeaders() {
      return [
        { text: this.$t('fields.contact'), value: 'contact_id.name' },
        { text: this.$t('fields.prefix_id'), value: 'prefix_id.name' },
        {
          text: this.$t('fields.name'),
          value: 'first_name'
        },
        {
          text: this.$t('fields.lastname'),
          value: 'last_name'
        },
        {
          text: this.$t('fields.id'),
          value: 'id_card'
        },

        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    approveFields() {
      return this.formData.document_template_id
        ? [...this.formData.document_template_id.fields]
            .map((field) => 'approve_' + field.field)
            .concat([
              'approve_contact_ids',
              'approve_type',
              'approve_document_template_id'
            ])
        : []
    },
    headersConditionValue() {
      return [
        {
          text: this.$t('fields.termsofsign'),
          value: 'result',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    headersConditionItem() {
      return [
        {
          text: this.$t('fields.documentType'),
          value: 'document_type',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.contactIds'),
          value: 'list_contact_id',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    },
    documentRemain() {
      return this.documentType.filter(
        (all) =>
          !this.conditionItems.map((item) => item.document_type).includes(all)
      )
    }
  },
  data() {
    return {
      documentReady: false,
      formData: {
        state: 'draft',
        fields: {}
      },
      selected: [],
      serverItemsLength: 0,
      serverConditionItemsLength: 0,
      items: [],
      loading: false,
      search: '',
      editedItem: {
        name: null,
        id: null,
        contact: null
      },
      savedItem: {},
      dialog: false,
      dialogField: false,
      apiMethod: 'post',
      editId: null,
      dialogCheckCondition: false,
      dateCheckCondition: null,
      documentCheckCondition: null,
      listNameCheckCondition: null,
      documentType: ['Finance', 'Purchase', 'Personnel'],
      checkResult: null,
      dialogCreateCondition: false,
      conditionsType: ['x of all', 'กรรมการที่เลือก', 'กรรมการคนใดคนหนึ่ง'],
      document_type: null,
      contact_ids: null,
      conditionValue: {},
      savedConditionItem: { conditions: [] },
      selectedConditionValue: [],
      selectedConditionItems: [],
      editConditionId: null,
      conditionItems: []
    }
  },
  methods: {
    contactRouter(item) {
      return {
        name: 'contactEdit',
        params: { id: item.id }
      }
    },
    async loadDocuments() {
      this.formData.documents = await Promise.all(
        this.formData.documents.map((document) => getFile(document))
      )
      this.documentReady = true
    },
    fieldSelectionItems(field) {
      if (field.field_type !== 'select') return []
      return field.selection_members.map((value) => ({
        ...value,
        value: value.label
      }))
    },
    prepareData(data) {
      data.contact_ids = data.contact_ids.map((value) => value.id)
      data.document_template_id = data.document_template_id.id
      data.customer_company_id = data.customer_company_id.id
      data.type = 'company'
      data.active = true
      return data
    },
    getItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `special-doc/director-doc?personal_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.items = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `special-doc/director-doc/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getItems()
        })
      })
      this.loading = false
    },
    save() {
      this.loading = true

      if (
        this.editedItem.contact_id !== null &&
        this.editedItem.contact_id !== undefined
      ) {
        this.savedItem.contact_id = this.editedItem.contact_id.id
      }
      if (
        this.editedItem.first_name !== null &&
        this.editedItem.first_name !== undefined
      ) {
        this.savedItem.first_name = this.editedItem.first_name
      }
      if (
        this.editedItem.last_name !== null &&
        this.editedItem.last_name !== undefined
      ) {
        this.savedItem.last_name = this.editedItem.last_name
      }

      if (
        this.editedItem.id_card !== null &&
        this.editedItem.id_card !== undefined
      ) {
        this.savedItem.id_card = this.editedItem.id_card
      }
      if (
        this.editedItem.prefix_id !== null &&
        this.editedItem.prefix_id !== undefined
      ) {
        this.savedItem.prefix_id = this.editedItem.prefix_id.id
      }
      this.savedItem.personal_id = this.$route.params.id

      const data = this.savedItem
      var itemID = ''
      if (this.editId !== null) {
        itemID = this.editId + '/'
      }

      this.$api({
        method: this.apiMethod,
        url: `special-doc/director-doc/` + itemID,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.close()
      this.getItems()
    },
    deleteSelect(item) {
      this.editedFieldIndex = this.formData.fields.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.items.splice(this.editedFieldIndex, 1)
    },
    onEdit(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getDataDetails() {
      if (
        this.editedItem.contact_id !== null &&
        this.editedItem.contact_id !== undefined
      ) {
        this.editedItem.prefix_id = this.editedItem.contact_id.prefix_id
        this.editedItem.first_name = this.editedItem.contact_id.first_name
        this.editedItem.last_name = this.editedItem.contact_id.last_name
        this.editedItem.id_card = this.editedItem.contact_id.national_id_no
        this.$refs.prefix.setDefaultServerItems(this.editedItem.prefix_id)
      }
    },
    getDocData() {
      this.$api({
        method: 'get',
        url: `doc-template/document/?search=หนังสือรับรองบริษัท`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.formData.document_template_id = data.results[0]
        this.loading = false
      })
    },
    checkCondition() {
      return this.dateCheckCondition === null ||
        this.dateCheckCondition === undefined ||
        this.documentCheckCondition === null ||
        this.documentCheckCondition === undefined ||
        this.listNameCheckCondition === null ||
        this.listNameCheckCondition.length === 0
        ? (this.checkResult = 'กรุณากรอกข้อมูลให้ครบถ้วน')
        : this.documentCheckCondition === 'Finance'
        ? (this.checkResult = 'ผ่านการตรวจสอบ')
        : (this.checkResult = 'ไม่ผ่านการตรวจสอบ')
    },
    saveConditionValue() {
      this.loading = true
      if (
        this.conditionValue.type !== null &&
        this.conditionValue.type !== undefined &&
        this.conditionValue.condition !== null &&
        this.conditionValue.condition !== undefined
      ) {
        if (this.conditionValue.type === 'x of all') {
          this.conditionValue.result = `${this.conditionValue.condition} of all`
        }
        if (this.conditionValue.type === 'กรรมการที่เลือก') {
          this.conditionValue.result =
            this.conditionValue.condition.join(' และ ')
        }
        if (this.conditionValue.type === 'กรรมการคนใดคนหนึ่ง') {
          this.conditionValue.result =
            this.conditionValue.condition.join(' หรือ ')
        }

        this.savedConditionItem.conditions.push(this.conditionValue)
        this.loading = false
        this.resetConditionValue()
      }
    },
    resetConditionValue() {
      this.conditionValue = Object.assign({}, this.defaultItems)
    },
    onDeleteConditionValue(item) {
      this.conditionValueIndex =
        this.savedConditionItem.conditions.indexOf(item)
      this.savedConditionItem.conditions.splice(this.conditionValueIndex, 1)
      this.selectedConditionValue = []
    },
    saveConditionItem() {
      this.loading = true
      if (this.document_type !== null && this.document_type !== undefined) {
        this.savedConditionItem.document_type = this.document_type
      }
      if (this.contact_ids !== null && this.contact_ids !== undefined) {
        this.savedConditionItem.list_contact_id = this.contact_ids.join(', ')
      }
      this.savedConditionItem.personal_id = this.$route.params.id

      const data = this.savedConditionItem
      var itemID = ''
      if (this.editConditionId !== null) {
        itemID = this.editConditionId + '/'
      }

      this.$api({
        method: this.apiMethod,
        url: `special-doc/signing-condition/` + itemID,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.dialogCreateCondition = false
      this.resetConditionItem()
      this.getConditionItems()
    },
    resetConditionItem() {
      this.document_type = null
      this.contact_ids = null
      this.resetConditionValue()
      this.savedConditionItem.conditions = []
    },
    onDeleteConditionItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `special-doc/signing-condition/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selectedConditionItems = []
          this.getConditionItems()
        })
      })
      this.loading = false
    },
    getConditionItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `special-doc/signing-condition?personal_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.serverConditionItemsLength = data.count
          this.conditionItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    }
  },
  created() {
    this.getDocData()
  },
  watch: {
    dialogCheckCondition(state) {
      if (state) {
        this.dateCheckCondition = null
        this.documentCheckCondition = null
        this.listNameCheckCondition = null
        this.checkResult = null
      }
    },
    dialogCreateCondition(state) {
      if (state) {
        this.resetConditionItem()
      }
    }
  }
}
</script>
